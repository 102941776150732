@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2';

.categories {
  display: grid;
  justify-content: space-between;

  @include sm {
    grid-template-columns: repeat(8, max-content);
    gap: 1rem;
  }

  @include md {
    grid-template-columns: repeat(12, max-content);
  }

  @include lg {
    gap: 1.875rem 0;
  }
}

.swiperContainer {
  margin: 0;

  .swiperSlide {
    width: auto;
    height: auto;
  }

  .swiperElement {
    /* padding prevents a shadow of being cut off */
    padding: 5px;
  }
}
